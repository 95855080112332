import React, {Component} from 'react';

class InProgress extends Component {
  
  render() {
    return (
      <div className="inprogress-wrapper">
        <div className="simpleslide100">
          <div className="simpleslide100-item bg-img1"/>
        </div>
        
        <div className="flex-col-c-sb size1 overlay1">
          
          <div className="flex-col-c-m p-l-15 p-r-15 p-t-50 p-b-120">
          
          </div>
          
          <div className="flex-w flex-c-m p-b-35">
            <h4 className="l1-txt1 txt-center p-b-120 respon1">
              WE ARE PREPARING FOR LIFT OFF
            </h4>
            <h4 className="size-full l1-txt2 flex-c-m  m-r-3 m-l-3 m-b-30 bottom-text">
              In the meantime, you can find us on
            </h4>
            <a href="https://www.facebook.com/softwarefromspace/" rel="noopener noreferrer" target="_blank"
               className="size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-5">
              <i className="fa fa-facebook"/>
            </a>
            
            <a href="https://www.instagram.com/softwarefromspace/" rel="noopener noreferrer" target="_blank"
               className="size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-5">
              <i className="fa fa-instagram"/>
            </a>
            
            <a href="mailto:andrei@softwarefromspace.com" rel="noopener noreferrer" target="_blank"
               className="size3 flex-c-m how-social trans-04 m-r-3 m-l-3 m-b-5">
              <i className="fa fa-envelope"/>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default InProgress
