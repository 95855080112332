import React, {Component} from 'react';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter, Switch, BrowserRouter as Router, Route} from 'react-router-dom'
import NotFound from "../pages/NotFound";
import {Preloader} from "../components/Preloader";
import ProjectsState from '../reducers/Projects';
import {Footer} from "../components/Footer";
import InProgress from "../pages/InProgress";

class Root extends Component {
  
  render() {
    return (
      <Router>
          <Switch>
            <Route exact path="/" component={InProgress}/>
            <Route component={NotFound}/>
          </Switch>
          <Preloader/>
      </Router>
    
    );
  }
}

function mapStateToProps(state, props) {
  return {
    categories: state.Projects.categories
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      getCategories: ProjectsState.actions.getCategories
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root))
